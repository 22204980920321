import React from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardImg,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap";

// Core Components
import DemoNavbar from "components/navbars/DemoNavbar.js";
import DemoFooter from "components/footers/DemoFooter.js";
import Header5 from "components/headers/Header5.js";
// import Features1 from "components/features/Feature1.js";
// import Blogs6 from "components/blogs/Blogs6.js";
// import Pricing5 from "components/pricings/Pricing5.js";
// import Team1 from "components/teams/Team1.js";
// import Feature from "components/top/Feature";
// import Courses from "components/top/Courses";

function Top() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <DemoNavbar type="white" />
      <div className="wrapper">
        <Header5 />

        <Container className="mt-5 pt-4">
          {/* <Feature /> */}
          <h2>Our Product</h2>
          <Row className="mt-5 flex-scroll-sm-product ">
            <Col sm="12" md="6">
              <h5>■ レジュミーAI</h5>
              <p>AIによる履歴書・職務経歴書作成サービス</p>
              <a href="https://www.resumy.ai" target="_blank">
                <Card>
                  <img
                    width="100%"
                    src={require("assets/img/product/thumb_resumy.png")}
                    alt="Card image cap"
                  />
                </Card>
              </a>
            </Col>
            <Col sm="12" md="6">
              <h5>■ ビズリーAI</h5>
              <p>
                AIによる事業計画書作成サービス
                <br className="sm-only" />
              </p>
              <a href="https://www.bizly.ai" target="_blank">
                <Card>
                  <img
                    width="100%"
                    src={require("assets/img/product/thumb_bizly.png")}
                    alt="Card image cap"
                  />
                </Card>
              </a>
            </Col>
            <Col sm="12" md="6">
              <h5>■ コワーキングハブ</h5>
              <p>
                全国のコワーキングスペース検索サイト
                <br className="sm-only" />
              </p>
              <a href="https://coworking-hub.com" target="_blank">
                <Card>
                  <img
                    width="100%"
                    src={require("assets/img/product/thumb_coworking-hub.png")}
                    alt="Card image cap"
                  />
                </Card>
              </a>
            </Col>
            <Col sm="12" md="6">
              <h5>■ チョットワークス</h5>
              <p>副業・フリーランスの為の案件マッチングプラットフォーム</p>
              <a href="https://chott-works.com" target="_blank">
                <Card>
                  <img
                    width="100%"
                    src={require("assets/img/product/thumb_chott_works.png")}
                    alt="Card image cap"
                  />
                </Card>
              </a>
            </Col>
            <Col sm="12" md="6">
              <h5>■ インボイシークラウド</h5>
              <p>エンジニア向け 請求書・作業報告書作成サービス</p>
              <a href="https://invoicy-cloud.com" target="_blank">
                <Card>
                  <img
                    width="100%"
                    src={require("assets/img/product/thumb_invoicy_cloud.png")}
                    alt="Card image cap"
                  />
                </Card>
              </a>
            </Col>
          </Row>
        </Container>
        <Container>
          {/* <Feature /> */}
          <h2>Our lectures</h2>
          <p>
            我々は、ITにおける英語と日本語での情報格差を無くすために、最新の技術を実践を意識したチュートリアルとして配信しています
          </p>
          <Row className="mt-5 flex-scroll-sm">
            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/youtube-react-hooks-css-module/?referralCode=3CB1014F8220980C56DD"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course1.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で作る Youtube クローンアプリ React・React
                      Hooks編
                    </CardTitle>

                    <CardText>
                      現場で使える技術が満載！
                      Youtubeクローンアプリを作って、React, React Hooks,
                      ContextAPI, Module
                      CSS,を使ったモダン開発をマスターしよう！
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>
            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/nodejs-webrtc/?referralCode=89E7EAE782E89F32A7D1"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course2.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で作る ビデオチャットアプリケーション NodeJS +
                      WebRTC編
                    </CardTitle>

                    <CardText>
                      ZoomやSkypeなど普段何気なく使っているビデオチャットアプリケーションを実装してみよう！
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/react-gatsbyjs-contentful/?referralCode=8CDB3DEFB30704BE8062"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course3.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で学ぶ React製 静的サイトジェネレータ GatsbyJS +
                      CMS Contentfulブログ実装編
                    </CardTitle>

                    <CardText>
                      Static Site Generator の決定版 GatsbyJSと今話題のGraphQL +
                      Headless CMSのContentful を学んで Blazing fast な
                      ブログを実装しよう！
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/graphql-fullstack-node-react/?referralCode=3BB6A2868926650C7A7C"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course4.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で学ぶ GraphQL Fullstack 実践入門 - Node &
                      React編
                    </CardTitle>

                    <CardText>
                      フロントエンドだけじゃない！！サーバー構築からフロント実装まで！GraphQL
                      + React + Apollo + express JS + mongoDB 全て学んじゃおう！
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/graphql-fullstack-node-vue/?referralCode=E3EEA323BE32E7000E70"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course5.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で学ぶ GraphQL Fullstack 実践入門 - Node & Vue編
                    </CardTitle>

                    <CardText>
                      フロントエンドだけじゃない！！サーバー構築からフロント実装まで！GraphQL
                      + Vue + Apollo + express JS + mongoDB 全て学んじゃおう！
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/firebase-hosting-todo-reactreact-hooks/?referralCode=393F4286C534AE13CFC4"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course6.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で学ぶ Firebase Hosting + React Todoアプリ実装編
                      (React Hooks)
                    </CardTitle>

                    <CardText>
                      話題のFirebaseを使ってWebアプリケーションを公開しよう &
                      Todoリスト実装 with (React, React Hooks, ContextAPI,
                      Function Component)
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/typescript-typescript/?referralCode=232DEA2912718FE9C0C8"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course7.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で学ぶTypeScript 実践入門 -
                      最新モダン開発には欠かせないTypeScript をマスターしよう！
                    </CardTitle>

                    <CardText>
                      このコースは忙しく学習時間が取れないエンジニアの皆様に最短、最速でTypescriptの基礎を学べるようにデザインされたコースです。2020年
                      TypeScriptをマスターし、最新のモダン開発手法を一緒に身につけていきましょう！
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/firebase-hosting-vue-todo/?referralCode=6F9F5CAE1818D798E86B"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course8.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で学ぶ Firebase Hosting + Vue Todoアプリ実装
                    </CardTitle>

                    <CardText>
                      話題のFirebaseを使ってWebアプリケーションを公開しよう &
                      Todoリスト実装 with Vue + Bootstrap
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>

            <Col md="4" sm="12">
              <a
                href="https://www.udemy.com/course/2020-react-react-hooks/?referralCode=D0D62D10ED7044CD6F41"
                target="_blank">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/courses/course9.png")}
                    alt="Card image cap"
                  />

                  <CardBody>
                    <CardTitle tag="h6">
                      最短・最速で学ぶ React Hooks 完全ガイド！
                      現場で使えるReact開発+ステート管理をマスターしよう！
                    </CardTitle>

                    <CardText>
                      クラスコンポーネント ? Redux? もう古い !? React Hooks
                      の基礎から応用まで学習
                      2021年最新のモダンフロントエンド開発手法を身につけよう！
                    </CardText>
                  </CardBody>
                </Card>
              </a>
            </Col>
          </Row>
        </Container>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSe3uiww3h_kIFszEnOJZcpxdblwiIoW1RUsZ_pz0erk29XeYQ/viewform?embedded=true"
          width="100%"
          height="1400px"
          frameborder="0"
          marginheight="0"
          marginwidth="0">
          読み込んでいます…
        </iframe>
        {/* <Blogs6 />
        <Pricing5 />
        <Team1 /> */}
        <DemoFooter />
      </div>
    </>
  );
}

export default Top;
