import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import Top from "views/examples/Top.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Top {...props} />} />
      {/* <Route path="/index" exact render={(props) => <Index {...props} />} />
      <Route
        path="/sections"
        exact
        render={(props) => <Sections {...props} />}
      />
      <Route
        path="/presentation"
        exact
        render={(props) => <Presentation {...props} />}
      />
      <Route
        path="/about-us"
        exact
        render={(props) => <AboutUs {...props} />}
      />
      <Route
        path="/account-settings"
        exact
        render={(props) => <AccountSettings {...props} />}
      />
      <Route
        path="/blog-post"
        exact
        render={(props) => <BlogPost {...props} />}
      />
      <Route
        path="/blog-posts"
        exact
        render={(props) => <BlogPosts {...props} />}
      />
      <Route
        path="/chat-page"
        exact
        render={(props) => <ChatPage {...props} />}
      />
      <Route
        path="/checkout-page"
        exact
        render={(props) => <CheckoutPage {...props} />}
      />
      <Route
        path="/contact-us"
        exact
        render={(props) => <ContactUs {...props} />}
      />
      <Route
        path="/ecommerce"
        exact
        render={(props) => <Ecommerce {...props} />}
      />
      <Route path="/error" exact render={(props) => <Error {...props} />} />
      <Route
        path="/error-500"
        exact
        render={(props) => <Error500 {...props} />}
      />
      <Route
        path="/invoice-page"
        exact
        render={(props) => <InvoicePage {...props} />}
      />
      <Route
        path="/landing-page"
        exact
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/login-page"
        exact
        render={(props) => <LoginPage {...props} />}
      />
      <Route
        path="/pricing-page"
        exact
        render={(props) => <PricingPage {...props} />}
      />
      <Route
        path="/product-page"
        exact
        render={(props) => <ProductPage {...props} />}
      />
      <Route
        path="/profile-page"
        exact
        render={(props) => <ProfilePage {...props} />}
      />
      <Route
        path="/register-page"
        exact
        render={(props) => <RegisterPage {...props} />}
      />
      <Route
        path="/reset-page"
        exact
        render={(props) => <ResetPage {...props} />}
      />
      <Redirect to="/presentation" /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
