import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  Input,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

function Header5() {
  return (
    <>
      <header className="header-5">
        <Navbar className="navbar-absolute" expand="lg">
          <Container>
            <div className="navbar-translate">
              <NavbarBrand>
                <img
                  alt="Chott.inc"
                  className="ml-lg-5"
                  src={require("assets/img/ill/chott-logo.png")}
                  width="100%"></img>
              </NavbarBrand>
              {/* <button
                aria-controls="navbarSupportedContent"
                aria-expanded={false}
                aria-label="Toggle navigation"
                className="navbar-toggler"
                data-target="#example-header-6"
                data-toggle="collapse"
                id="example-header-6"
                type="button"
              >
                <span className="navbar-toggler-icon"></span>
              </button> */}
            </div>
            <UncontrolledCollapse
              id="example-header-6"
              navbar
              toggler="#example-header-6">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <img
                      alt="Chott.inc"
                      className="ml-lg-5"
                      src={require("assets/img/ill/chott-logo.png")}
                      width="100%"></img>
                  </Col>
                  <Col className="collapse-close text-right" xs="6">
                    <button
                      aria-controls="navigation-index"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#example-header-6"
                      data-toggle="collapse"
                      id="example-header-6"
                      type="button">
                      <span></span>
                      <span></span>
                    </button>
                  </Col>
                </Row>
              </div>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
        <div className="page-header">
          <Container>
            <Row className="align-items-center text-left">
              <Col lg="5" xs="12">
                <span>ITのチカラで世界をチョット面白くする</span>
                <h1 className="title">
                  <img
                    alt="Chott.inc"
                    className=""
                    src={require("assets/img/ill/kv-logo.png")}
                    width="100%"></img>
                </h1>
                <p className="lead pb-4">
                  チョットした変化を生むためにサービスを通じて世界を、社会を、人をつなぐことを目的に活動しています。
                </p>
                {/* <Row className="row-input">
                  <Col sm="8" xs="12">
                    <Input
                      aria-label="Your email"
                      id="signupSrEmail"
                      name="email"
                      placeholder="Your email"
                      type="email"
                    ></Input>
                  </Col>
                  <Col className="pl-lg-0" sm="4" xs="12">
                    <Button block color="primary" type="submit">
                      More
                    </Button>
                  </Col>
                </Row> */}
              </Col>
              <Col className="pl-0" lg="7" xs="12">
                <img
                  alt="Chott.inc"
                  className="ml-lg-5"
                  src={require("assets/img/ill/bg_kv.png")}
                  width="100%"></img>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
}

export default Header5;
